import React from 'react';
import Hero from "../components/sections/Hero";
import GenericSection from "../components/sections/GenericSection";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";

const AwsDevOps = () => {
    const structuredData = {
        "@context": "https://schema.org",
        "@type": "NewsArticle",
        "headline": "AWS DevOps",
        "datePublished": "2023-03-21T07:00:00+10:00",
        "dateModified": "2023-03-21T07:00:00+10:00",
        "author": [{
            "@type": "Person",
            "name": "Nathan Wilson",
            "url": "https://www.linkedin.com/in/nathan-wilson-19725191/"
        }]
    };
    
    return (
        <>
            <Helmet>
                <link rel="canonical" href="https://nathanwilson.com.au/aws-devops" />
                <script type="application/ld+json">
                    {JSON.stringify(structuredData)}
                </script>
            </Helmet>
            
            <Hero className="illustration-section-01" />
            <GenericSection>
                <h2 className="mt-0 mb-16">AWS DevOps</h2>
                <p className="m-0 pb-16">
                    At <Link to="/" className="content">NWC</Link> we specialize in providing high-quality DevOps services
                    using the <a className="content" href="https://aws.amazon.com/">Amazon Web Services (AWS) platform.</a> Our
                    team of experienced DevOps engineers can help you optimize your infrastructure for speed, scalability,
                    and reliability, leveraging the power of AWS.
                </p>
                <p className="m-0 pb-16">
                    With AWS, we can help you automate your software development and delivery processes, ensuring that your
                    applications are deployed quickly and reliably. Our AWS DevOps services include continuous integration
                    and delivery, infrastructure as code, monitoring and logging, security, and compliance.
                </p>
                <p className="m-0 pb-16">
                    One of the key benefits of using AWS for DevOps is its flexibility and scalability. With AWS, we can
                    quickly spin up new resources and scale them as needed, ensuring that your infrastructure can keep up
                    with the demands of your business. We can help you design and implement a cloud infrastructure that meets
                    your unique needs, from small-scale applications to large-scale enterprise solutions.
                </p>
                <p className="m-0 pb-16">
                    Our team of DevOps engineers is well-versed in the latest AWS tools and technologies, including Amazon EC2,
                    Amazon S3, AWS Lambda, Amazon RDS, AWS CodePipeline, AWS CloudFormation, and more. We use industry-leading
                    ools and techniques to ensure that your DevOps processes are optimized for performance and efficiency.
                </p>
                <p className="m-0 pb-16">
                    If you're looking for a reliable and experienced AWS DevOps partner, look no further
                    than <Link to="/" className="content">NWC</Link>. <Link to="/contact" className="content">Contact us</Link> today
                    to learn more about our services and how we can help you optimize your infrastructure for speed,
                    scalability, and reliability.
                </p>
            </GenericSection>
        </>
    );
}

export default AwsDevOps;