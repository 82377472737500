import React, {useEffect, useRef} from 'react';
import { Route } from 'react-router-dom';

const AppRoute = ({
  component: Component,
  layout: Layout,
  title: Title,
  structuredData: StructuredData,
  ...rest
}) => {

  Layout = (Layout === undefined) ? props => (<>{props.children}</>) : Layout;
  const structuredDataInjected = useRef(false);
  
  useEffect(() => {
      if (Title) {
          document.title = Title + " | " + process.env.REACT_APP_PAGE_TITLE;
      } else {
          document.title = process.env.REACT_APP_PAGE_TITLE;
      }
      
      if (structuredDataInjected.current) {
          return;
      }
      
      structuredDataInjected.current = true;
      
      if (StructuredData) {
          let script = document.createElement('script');
          script.type = 'application/ld+json';
          script.innerHTML = JSON.stringify(StructuredData);
          document.head.appendChild(script);
      }
  });

  return (
    <Route
      {...rest}
      render={props => (
        <Layout>
          <Component {...props} />
        </Layout>
      )} />
  );
}

export default AppRoute;