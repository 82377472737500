import React from 'react';
import Hero from "../components/sections/Hero";
import GenericSection from "../components/sections/GenericSection";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";

const ShopifySupport = () => {
    const structuredData = {
        "@context": "https://schema.org",
        "@type": "NewsArticle",
        "headline": "Shopify Support",
        "datePublished": "2023-03-21T07:00:00+10:00",
        "dateModified": "2023-03-21T07:00:00+10:00",
        "author": [{
            "@type": "Person",
            "name": "Nathan Wilson",
            "url": "https://www.linkedin.com/in/nathan-wilson-19725191/"
        }]
    };
    
    return (
        <>
            <Helmet>
                <link rel="canonical" href="https://nathanwilson.com.au/shopify-support" />
                <script type="application/ld+json">
                    {JSON.stringify(structuredData)}
                </script>
            </Helmet>
            
            <Hero className="illustration-section-01" />
            <GenericSection>
                <h2 className="mt-0 mb-16">Shopify Support</h2>
                <p className="m-0 pb-16">
                    At <Link to="/" className="content">NWC</Link> we specialize in providing high-quality Shopify development
                    services to businesses of all sizes. Our team of experienced developers can create custom solutions that
                    meet your unique needs, while also leveraging the out-of-the-box functionality of the Shopify platform.
                </p>
                <p className="m-0 pb-16">
                    One of the key benefits of using <a className="content" href="https://www.shopify.com/au">Shopify</a> is
                    its speed of delivery. With a vast array of built-in features and integrations, we can quickly create a
                    functional and visually appealing online store that meets your business needs. From product management to
                    payment processing and shipping integration, we can leverage the power of Shopify to deliver a fast and
                    reliable e-commerce solution.
                </p>
                <p className="m-0 pb-16">
                    Our Shopify development services include custom theme and app development, migration and integration
                    services, and ongoing maintenance and support. We work closely with our clients to understand their
                    business requirements and goals, and we use our expertise to deliver a solution that exceeds their
                    expectations.
                </p>
                <p className="m-0 pb-16">
                    Our team of developers is well-versed in the latest web development technologies and practices, ensuring
                    that your Shopify store is optimized for performance and speed. We use industry-leading tools and
                    techniques to ensure that your online store is fast, responsive, and user-friendly.
                </p>
                <p className="m-0 pb-16">
                    If you're looking for a reliable and experienced Shopify development partner, look no further
                    than <Link to="/" className="content">NWC</Link>. <Link to="/contact" className="content">Contact us</Link> today to learn more about our services
                    and how we can help you take your online business to the next level.
                </p>
            </GenericSection>
        </>
    );
}

export default ShopifySupport;