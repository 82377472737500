import React, {useEffect, useState} from 'react';
// import sections
import Hero from '../components/sections/Hero';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import axios from "axios";
import FeaturesGeneric from "../components/sections/FeaturesGeneric";

const Home = () => {
    const [sections, setSections] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loaded, setLoaded] = useState(false);
    
    useEffect(() => {
        if (!loading && !loaded) {
            
            setLoading(true);
            axios.get(process.env.REACT_APP_CMS_BASE_URL+"/api/homepage?populate=deep")
                .then((response) => {
                    setSections(response.data.data.attributes.sections.data);
                    setLoaded(true);
                });
        }
    }, [loading, loaded]);
    
    return (
        <>
            <Hero className="illustration-section-01" />
            {sections.map((section) => {
                if (section.attributes.SectionType === 'generic') {
                    return <FeaturesGeneric key={section.id} invertMobile imageFill className="illustration-section-02" section={section} />
                }
                if (section.attributes.SectionType === 'tiles') {
                    return <FeaturesTiles section={section} />
                }
                
                return <p key={section.id}>{section.attributes.Header}</p>
            })}
        </>
    );
}

export default Home;