import React, { useRef, useEffect } from 'react';
import { useLocation, Switch } from 'react-router-dom';
import AppRoute from './utils/AppRoute';
import ScrollReveal from './utils/ScrollReveal';

// Layouts
import LayoutDefault from './layouts/LayoutDefault';

// Views 
import Home from './views/Home';
import About from './views/About';
import LayoutContent from "./layouts/LayoutContent";
import Contact from "./views/Contact";
import MagentoSupport from "./views/MagentoSupport";
import ShopifySupport from "./views/ShopifySupport";
import AwsDevOps from "./views/AwsDevOps";


const App = () => {

  const childRef = useRef();
  let location = useLocation();

  useEffect(() => {
    document.body.classList.add('is-loaded')
    childRef.current.init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <ScrollReveal
      ref={childRef}
      children={() => (
        <Switch>
          <AppRoute exact path="/" component={Home} layout={LayoutDefault} />
          <AppRoute exact path="/about" component={About} layout={LayoutContent} title='About Us' />
          <AppRoute exact path="/contact" component={Contact} layout={LayoutContent} title='Contact Us' />
          <AppRoute exact path="/magento-support" component={MagentoSupport} layout={LayoutContent} title='Magento Support' />
          <AppRoute exact path="/magento-support-brisbane" component={MagentoSupport} layout={LayoutContent} title='Magento Support' />
          <AppRoute exact path="/shopify-support" component={ShopifySupport} layout={LayoutContent} title='Shopify Support' />
          <AppRoute exact path="/shopify-support-brisbane" component={ShopifySupport} layout={LayoutContent} title='Shopify Support' />
          <AppRoute exact path="/aws-devops" component={AwsDevOps} layout={LayoutContent} title='AWS DevOps' />
          <AppRoute exact path="/aws-devops-brisbane" component={AwsDevOps} layout={LayoutContent} title='AWS DevOps' />
        </Switch>
      )} />
  );
}

export default App;