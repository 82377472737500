import React from 'react';
import Hero from "../components/sections/Hero";
import GenericSection from "../components/sections/GenericSection";
import {Link} from "react-router-dom";

const Contact = () => {
    return (
        <>
            <Hero className="illustration-section-01" />
            <GenericSection className='contact'>
                <h2 className="mt-0 mb-16">Contact</h2>
                <p className="m-0 pb-24">
                    Please complete the form below or send us an email to enquire about our services.
                    Be descriptive, tell us about you and which services you require.
                    Also include the best means and times to get in touch.
                </p>
                <p className="m-0 pb-16"><span className='label'>Email:</span> <Link to='#' onClick={(e) => {window.location.href = 'mailto:contact@nathanwilson.com.au'; e.preventDefault();}}>contact@nathanwilson.com.au</Link></p>
                <div className="klaviyo-form-ViYx7q"></div>
            </GenericSection>
        </>
    );
}

export default Contact;