import React from 'react';
import Hero from "../components/sections/Hero";
import GenericSection from "../components/sections/GenericSection";

const About = () => {
    return (
        <>
            <Hero className="illustration-section-01" />
            <GenericSection>
                <h2 className="mt-0 mb-16">About Us</h2>
                <p className="m-0 pb-16">
                    Starting out in development in the late nineties NWC have honed their skills across many facets of
                    the industry, from online gaming to skateboards, higher education to government, working with and for
                    a range of companies from giant multinationals to owner operated businesses. With 25 years experience 
                    predominantly in the online space including the last 10 focusing on eCommerce NWC have a broad 
                    perspective on what works both for an online business and the teams and processes required to support it.
                </p>
                <p className="m-0 pb-16">
                    Whilst NWC understands what works as a general rule, there is no guess work. All businesses
                    are different and what works for some may not work work for others. Given the strong technical
                    background at NWC the approaches taken are always pragmatic, methodical, measured and data driven.
                </p>
            </GenericSection>
        </>
    );
}

export default About;