import React from 'react';
import classNames from 'classnames';

const FooterSocial = ({
  className,
  ...props
}) => {

  const classes = classNames(
    'footer-social',
    className
  );

  return (
    <div
      {...props}
      className={classes}
    >
      <ul className="list-reset">
        <li>
          <a href="https://www.linkedin.com/in/nathan-wilson-19725191/">
            <svg xmlns="http://www.w3.org/2000/svg" width="32px" height="32px" viewBox="0 0 32 32" version="1.1" className="social-linkedin">
              <g id="surface1">
                <path className="social-linkedin-1" d="M 16 0 C 24.835938 0 32 7.164062 32 16 C 32 24.835938 24.835938 32 16 32 C 7.164062 32 0 24.835938 0 16 C 0 7.164062 7.164062 0 16 0 Z M 16 0 "/>
                <path className="social-linkedin-2" d="M 9.015625 21.984375 L 12.011719 21.984375 L 12.011719 11.996094 L 9.015625 11.996094 Z M 20.6875 11.652344 C 19.234375 11.652344 17.933594 12.183594 17.011719 13.355469 L 17.011719 11.964844 L 14.003906 11.964844 L 14.003906 21.984375 L 17.011719 21.984375 L 17.011719 16.566406 C 17.011719 15.421875 18.058594 14.304688 19.375 14.304688 C 20.6875 14.304688 21.011719 15.421875 21.011719 16.539062 L 21.011719 21.984375 L 24.007812 21.984375 L 24.007812 16.316406 C 24.007812 12.378906 22.144531 11.652344 20.6875 11.652344 Z M 10.5 11 C 11.328125 11 12 10.328125 12 9.5 C 12 8.671875 11.328125 8 10.5 8 C 9.671875 8 9 8.671875 9 9.5 C 9 10.328125 9.671875 11 10.5 11 Z M 10.5 11 "/>
              </g>
            </svg>
          </a>
        </li>
      </ul>
    </div>
  );
}

export default FooterSocial;