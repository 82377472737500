import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
    ...SectionSplitProps.types
}

const defaultProps = {
    ...SectionSplitProps.defaults
}

const FeaturesGeneric = ({
   className,
   topOuterDivider,
   bottomOuterDivider,
   topDivider,
   bottomDivider,
   hasBgColor,
   invertColor,
   invertMobile,
   invertDesktop,
   alignTop,
   imageFill,
   section,
   ...props
}) => {

    const outerClasses = classNames(
        'features-split section',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'features-split-inner section-inner',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );

    const splitClasses = classNames(
        'split-wrap',
        invertMobile && 'invert-mobile',
        invertDesktop && 'invert-desktop',
        alignTop && 'align-top'
    );

    const sectionHeader = {
        title: section.attributes.Header,
        anchor: section.attributes.Header.toLowerCase().replace(/\s/g, "-"),
        paragraph: section.attributes.ShortDescription
    };

    return (
        <section
            {...props}
            className={outerClasses}
        >
            <div className="container">
                <div className={innerClasses}>
                    <SectionHeader data={sectionHeader} className="center-content" />
                    <div className={splitClasses}>
                        {section.attributes.blocks.data.map((block) => {
                            return <div key={block.id} className="split-item">
                                <div className="split-item-content center-content-mobile" data-reveal-container=".split-item">
                                    <div className="text-xxs text-color-primary-alt fw-600 tt-u mb-8">{block.attributes.SuperHeader}</div>
                                    <h3 className="mt-0 mb-12">{block.attributes.Header}</h3>
                                    <p className="m-0">{block.attributes.Content}</p>
                                </div>
                                <div className={
                                    classNames(
                                        'split-item-image center-content-mobile',
                                        imageFill && 'split-item-image-fill'
                                    )}
                                     data-reveal-container=".split-item">
                                    <Image
                                        src={process.env.REACT_APP_CMS_BASE_URL+block.attributes.Image.data[0].attributes.url}
                                        alt="Features split 02"
                                        width={528}
                                        height={396} />
                                </div>
                            </div>
                        })}
                    </div>
                </div>
            </div>
        </section>
    );
}

FeaturesGeneric.propTypes = propTypes;
FeaturesGeneric.defaultProps = defaultProps;

export default FeaturesGeneric;