import React from 'react';
import Hero from "../components/sections/Hero";
import GenericSection from "../components/sections/GenericSection";
import { Helmet} from "react-helmet";

const MagentoSupport = () => {
    const structuredData = {
            "@context": "https://schema.org",
            "@type": "NewsArticle",
            "headline": "Magento/Adobe Commerce Support",
            "datePublished": "2023-03-21T07:00:00+10:00",
            "dateModified": "2023-03-21T07:00:00+10:00",
            "author": [{
                "@type": "Person",
                "name": "Nathan Wilson",
                "url": "https://www.linkedin.com/in/nathan-wilson-19725191/"
            }]
    };
    
    return (
        <>
            <Helmet>
                <link rel="canonical" href="https://nathanwilson.com.au/magento-support" />
                <script type="application/ld+json">
                    {JSON.stringify(structuredData)}
                </script>
            </Helmet>
            
            <Hero className="illustration-section-01" />
            <GenericSection>
                <h2 className="mt-0 mb-16">Magento/Adobe Commerce Support</h2>
                <p className="m-0 pb-16">
                    Having a trusted partner to support your <a className="content" href="https://business.adobe.com/products/magento/magento-commerce.html">Magento (powered by Adobe)</a> platform
                    is a critical component for businesses looking to maximize the potential of their
                    e-commerce platform. NWC provides businesses with the technical expertise and resources needed to
                    keep their online store running smoothly. Whether it's troubleshooting, performance optimization,
                    or patching and security updates, NWC experts are available 24/7 to help businesses overcome any
                    challenges they may face.
                </p>
                <p className="m-0 pb-16">
                    In today's fast-paced digital landscape, having a reliable e-commerce platform is essential for
                    success. With NWC supporting your Magento platform, businesses can be confident that their online
                    store is always functioning at its best. From custom plugin development to search engine
                    optimization (SEO) best practices, NWC experts are equipped to handle all aspects of an e-commerce
                    website, helping businesses to stay ahead of the competition.
                </p>
                <p className="M-0 pb-16">
                    For businesses looking to improve their online presence and drive more sales, NWC supporting your
                    Magento platform is a valuable investment.
                </p>
            </GenericSection>
        </>
    );
}

export default MagentoSupport;